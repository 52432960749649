import React from 'react';
import { Link } from 'gatsby';
import Button from 'react-bootstrap/Button';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const PageNotFound = () => {
  return (
    <Layout footer={false}>
      <Seo title="Page Not Found | Bridge" description="Page Not Found" />
      <Section variant="hero">
        <h1 className="text-center">Page Not Found</h1>
        <p className="text-secondary text-center fw-bold">
          Sorry we couldn&apos;t find what you were looking for.
        </p>
        <p className="text-secondary text-center fw-bold">
          <Button as={Link} variant="primary" to="/">
            Back To home
          </Button>
        </p>
      </Section>
    </Layout>
  );
};

export default PageNotFound;
